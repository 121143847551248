@import-normalize;

html{  
  height: 100%;
}

body{  
  min-height: 100%;
}

::selection {
  background: #D2A3D9;
}

body {
  margin: 0;
  font-family: "Mukta", Arial, sans-serif;  
  background: url(./images/background.png) no-repeat center;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
