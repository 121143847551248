p{
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 44px;
}

h1{
  font-family: 'Mukta', sans-serif;
  font-size: 72px;
  line-height: 88px;
}

.home{
  width: 100%;
  min-height: 100vh;
  padding:8px;
  box-sizing: border-box;
}